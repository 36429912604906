import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const TestDrivePage = ({ data }) => {
  return (
    <Layout>
      <div className="masthead relative">
        <div className="masthead hidden lg:block">
          <GatsbyImage
            image={getImage(
              data.allMdx.edges[0].node.frontmatter.masthead_desktop
            )}
            alt={data.allMdx.edges[0].node.frontmatter.title}
          />
        </div>
        <div className="masthead block lg:hidden">
          <GatsbyImage
            image={getImage(
              data.allMdx.edges[0].node.frontmatter.masthead_mobile
            )}
            alt={data.allMdx.edges[0].node.frontmatter.title}
          />
        </div>
        <div className="relative md:absolute text-black md:text-white top-0 left-0 w-full h-full">
          <div className="container py-5 md:mt-36">
            <h1 className="font-light text-2xl md:text-4xl xl:text-5xl">
              {data.allMdx.edges[0].node.frontmatter.masthead_line1 !== ""}
              <span className="inline md:block">
                {data.allMdx.edges[0].node.frontmatter.masthead_line1}
                {data.allMdx.edges[0].node.frontmatter.masthead_line2 !==
                  "" && <span className="inline">&nbsp;</span>}
              </span>
              {data.allMdx.edges[0].node.frontmatter.masthead_line2 !== "" && (
                <span className="inline md:block md:mt-1">
                  {data.allMdx.edges[0].node.frontmatter.masthead_line2}
                </span>
              )}
            </h1>
          </div>
        </div>
      </div>
      <section className="section sectionFirst">
        <div className="container py-10">
          <iframe
            className="w-full h-[1700px] md:h-[1500px]"
            src="https://cloud.mail.ea-bmw.com.sg/Test_Drive_Request"
            border="0"
            frameborder="0"
          ></iframe>
        </div>
      </section>
      <script
        dangerouslySetInnerHTML={{
          __html: `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '608633623033362');
          fbq('track', 'PageView');`,
        }}
      ></script>
      <noscript>
        <img
          height="1"
          width="1"
          className="hidden"
          src="https://www.facebook.com/tr?id=608633623033362&ev=PageView&noscript=1"
        />
      </noscript>
    </Layout>
  )
}
export const query = graphql`
  query {
    allMdx(filter: { frontmatter: { page_id: { eq: "test-drive" } } }) {
      edges {
        node {
          frontmatter {
            slug
            page_id
            date
            title
            description
            masthead_line1
            masthead_line2
            masthead_desktop {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
            masthead_mobile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 150 }
                  placeholder: BLURRED
                  breakpoints: [650]
                  outputPixelDensities: [2]
                )
              }
            }
          }
        }
      }
    }
  }
`

export default TestDrivePage

const seoDetails = {
  title: "Eurokars Auto | Book a Test Drive",
  description: "Book a test drive today.",
  keywords: "BMW Test Drive, Test Drive, Eurokars Auto Test Drive",
  image: "og-eka-test-drive.jpg",
}

export const Head = () => (
  <>
    <title>{seoDetails.title}</title>
    <meta property="og:type" content="website" />
    <meta
      name="description"
      property="description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="og:description"
      property="og:description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="keywords"
      property="keywords"
      content={seoDetails.keywords}
    ></meta>
    <meta
      name="og:url"
      property="og:url"
      content={`${process.env.GATSBY_BASE_URL}`}
    ></meta>
    <meta
      name="og:image"
      property="og:image"
      content={`${process.env.GATSBY_BASE_URL}/img/${seoDetails.image}`}
    ></meta>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=AW-782099393"
    ></script>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-8FJZCNFV3R"
    ></script>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=GTM-M7DKTD9"
    ></script>
  </>
)
